[data-nav-style="menu-hover"][data-nav-layout="horizontal"] {
  @extend .menu-hover;
  @media (min-width: 992px) {
    .app-sidebar {
      .side-menu__item {
        display: flex !important;
        padding-top: 0.93rem;
        padding-inline-end: 1.75rem;
        padding-bottom: 0.8rem;
        padding-inline-start: 0.75rem;
      }
      .side-menu__icon {
        margin-block-end: 0 !important;
        margin-inline-end: 0.35rem !important;
      }
      .side-menu__angle {
        display: block !important;
      }
      .slide.has-sub .slide-menu {
        &.active {
          inset-inline-start: auto !important;
          inset-inline-end: auto !important;
        }
        &.child1 {
          inset-block-start: 100% !important;
        }
      }
    }
  }
}
[data-nav-style="menu-hover"][data-toggled="menu-hover-closed"] {
  @extend .menu-hover;
}
[data-nav-style="menu-hover"][data-nav-layout="vertical"][data-toggled="menu-hover-closed"] {
  @media (min-width: 992px) {
    .app-sidebar {
      position: absolute;
      .main-sidebar-header {
        .header-logo {
          .desktop-logo {
            display: none;
          }
          .toggle-logo {
            display: block;
          }
        }
      }
      .side-menu__item {
        border-radius: 0.5rem;
        margin: 0.25rem 0.25rem 0 0.25rem !important;
        &:last-child {
          margin: 0.25rem !important;
        }
      }
      .slide.has-sub .slide-menu {
        border-start-end-radius: 0.4rem;
        border-end-end-radius: 0.4rem;
      }
    }
    .page-header-breadcrumb {
      padding-inline-start: 11rem;
    }
    &[data-menu-styles="dark"] {
      .app-sidebar {
        .main-sidebar-header {
          .header-logo {
            .desktop-logo,
            .toggle-logo,
            .desktop-dark {
              display: none;
            }
            .toggle-dark {
              display: block;
            }
          }
        }
      }
    }
  }
}
.menu-hover {
  @media (min-width: 992px) {
    .app-sidebar {
      width: 9.5rem;
      .main-sidebar {
        overflow: visible;
        height: 90%;
      }
      .main-sidebar-header {
        width: 9.5rem;
      }
      .side-menu__icon {
        margin-inline-end: 0;
        margin-block-end: 0.5rem;
      }
      .slide {
        padding: 0;
      }
      .slide-menu {
        &.child1,
        &.child2,
        &.child3 {
          min-width: 12rem;
          display: none !important;
          .slide {
            .side-menu__item {
              text-align: start;
              &:before {
                inset-block-start: 0.938rem;
                inset-inline-start: 0.75rem;
              }
            }
          }
          .slide.has-sub,
          .slide {
            &.side-menu__label1 {
              display: none;
            }
          }
        }
      }
      .slide.has-sub:hover {
        .slide-menu.child1 {
          display: block !important;
          .slide.has-sub:hover {
            .slide-menu.child2 {
              display: block !important;
              .slide.has-sub:hover {
                .slide-menu.child3 {
                  display: block !important;
                }
              }
            }
          }
        }
      }
      .slide__category,
      .side-menu__angle {
        display: none;
      }
      .slide-menu {
        &.child1,
        &.child2,
        &.child3 {
          .side-menu__angle {
            display: block;
            inset-inline-end: 1rem;
            inset-block-start: 0.65rem;
          }
        }
      }
      .side-menu__item,
      .side-menu__label {
        display: block;
        text-align: center;
      }
      .slide.has-sub .slide-menu {
        position: absolute !important;
        inset-inline-start: 9.5rem !important;
        background: var(--menu-bg);
        inset-block-start: auto !important;
        box-shadow: 0.125rem 0.063rem 0.5rem $black-1;
        transition: none !important;
        &.child2,
        &.child3 {
          inset-inline-start: 12rem !important;
        }
      }
      .simplebar-content-wrapper {
        position: initial;
      }
      .simplebar-mask {
        position: inherit;
      }
      .simplebar-placeholder {
        height: auto !important;
      }
    }
    .app-header {
      padding-inline-start: 9.5rem;
    }
    .app-content {
      margin-inline-start: 9.5rem;
    }
  }
}

@media (min-width: 992px) {
  [data-nav-style="menu-hover"][data-nav-layout="vertical"] {
    &[data-header-position="rounded"] {
      .app-header.sticky-pin {
        margin-inline-start: 16rem;
        width: calc(100% - 17rem);
        padding-inline-start: 0;
      }
      &[data-toggled="menu-hover-closed"] {
        .app-header.sticky-pin {
        margin-inline-start: 10rem;
        width: calc(100% - 11rem);
        }
      }
    }
  }
}
