[data-vertical-style="closed"] {
  &[data-toggled="close-menu-close"] {
    .app-sidebar {
      display: none;
    }
    @media (min-width: 992px) {
      .app-content {
        margin-inline-start: 0;
      }
      .app-header {
        padding-inline-start: 0;
      }
      .page-header-breadcrumb {
        padding-inline-start: 1.45rem;
      }
    }
  }
  &[data-toggled="close-menu-open"] {
    .app-sidebar {
      display: block;
    }
    @media (min-width: 992px) {
      .app-content {
        margin-inline-start: 15rem;
      }
      .app-header {
        padding-inline-start: 15rem;
      }
      .page-header-breadcrumb {
        padding-inline-start: 16.4rem;
      }
    }
  }

  @media (min-width: 992px) {
    &[data-header-position="rounded"] {
        .app-header.sticky-pin {
          margin-inline-start: 16rem;
          width: calc(100% - 17rem);
          padding-inline-start:0;
        }
      &[data-toggled="close-menu-close"] {
        &:not([data-icon-overlay="open"]) {
          .app-header.sticky-pin {
            margin-inline: auto;
            width: calc(100% - 2rem);
          }
        }
      }
    }
  }
}
