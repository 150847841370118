/* Start:: Breadcrumb Styles */

.page-header-breadcrumb {
  .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, "\ea61");
    font-family: "tabler-icons" !important;
    color: rgba(255, 255, 255, 0.5);
  }
    flex-wrap: wrap;
    position: relative;
    min-height: 115px;
    border: 1px solid transparent;
    border-radius: 0px;
    background:$header-bg;
    margin: 0px 0px -24px 0px;
    padding-block-start: 0.2rem ;
    padding-inline-end: 1.45rem ;
    padding-block-end: 3rem;
    padding-inline-start: 16.4rem;
    background-position: center;
    background-size: cover;
    color: $white;
    top: 4rem;
  .breadcrumb {
    margin-block-end: 0px;
    .breadcrumb-item {
      a {
        color: $white;
      }

      &.active {
        color: $white;
        font-weight: 600;
      }
    }
  }
}
.breadcrumb {
  .breadcrumb-item {
    a {
      color: $primary;
    }

    &.active {
      color: $default-text-color;
      font-weight: 600;
    }
  }
}
.breadcrumb-item+.breadcrumb-item::before {
  color: $text-muted;
  opacity: 0.5;
}
.breadcrumb-example1 {
  .breadcrumb-item+.breadcrumb-item::before {
    color: $text-muted;
    content: var(--bs-breadcrumb-divider, "\ea65");
    font-family: "tabler-icons" !important;
  }
}
.breadcrumb-style1 {
  .breadcrumb-item+.breadcrumb-item::before {
    color: $text-muted;
    content: var(--bs-breadcrumb-divider, "\ea1c");
    font-family: "tabler-icons" !important;
  }
}
.breadcrumb-style2 {
  .breadcrumb-item+.breadcrumb-item::before {
    color: $text-muted;
    content: var(--bs-breadcrumb-divider, "\ea61");
    font-family: "tabler-icons" !important;
  }
}
.embedded-breadcrumb:before {
  opacity: 0.7;
}
[data-theme-mode="dark"] {
  .embedded-breadcrumb:before {
    filter: invert(1);
  }
}
@media (max-width: 991px) {
  .page-header-breadcrumb {
    // padding-block-start: 0.2rem  !important;
    padding-block-start: 1.5rem  !important;
    padding-inline-end: 1.3rem !important;
    // padding-block-end: 3rem !important;
    padding-block-end: 4rem !important;
    padding-inline-start: 1.4rem !important;
  }
}
/* End:: Breadcrumb Styles */