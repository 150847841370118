[data-nav-style="icon-click"][data-nav-layout="horizontal"],
[data-nav-style="icon-click"][data-toggled="icon-click-closed"] {
  @extend .icon-click;
}
.icon-click {
  @media (min-width: 992px) {
    .app-sidebar {
      width: 5rem;

      .main-sidebar {
        overflow: visible;
        height: 90%;
      }

      .main-sidebar-header {
        width: 5rem;

        .header-logo {
          .toggle-logo {
            display: block;
          }

          .desktop-dark,
          .desktop-logo,
          .toggle-dark {
            display: none;
          }
        }
      }

      .category-name,
      .side-menu__label,
      .side-menu__angle {
        display: none;
      }

      .side-menu__icon {
        margin-inline-end: 0;
      }

      .slide__category {
        padding: 1.2rem 1.65rem;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          inset-inline-start: 2.25rem;
          inset-inline-end: 0;
          inset-block-start: 1.25rem;
          inset-block-end: 0;
          width: 0.35rem;
          border-radius: 3.125rem;
          height: 0.35rem;
          border: 1px solid $menu-prime-color;
          opacity: 1;
        }
      }

      .simplebar-content-wrapper {
        position: initial;
      }

      .simplebar-mask {
        position: inherit;
      }

      .simplebar-placeholder {
        height: auto !important;
      }
    }

    .app-header {
      padding-inline-start: 5rem;
    }

    .app-content {
      margin-inline-start: 5rem;
    }

    .slide {
      &.side-menu__label1 {
        display: block;
        font-size: 1.1rem;
        padding: 0.5rem 1rem !important;
        border-block-end: 1px solid $default-border;
      }
    }

    .slide.has-sub .slide-menu {
      position: absolute !important;
      inset-inline-start: 5rem !important;
      background: var(--menu-bg);
      inset-block-start: auto !important;
      box-shadow: 0.125rem 0.063rem 0.5rem $black-1;
      transition: none !important;

      &.child2,
      &.child3 {
        inset-inline-start: 12rem !important;
      }
    }

    .slide-menu {
      &.child1,
      &.child2,
      &.child3 {
        min-width: 12rem;

        .slide {
          .side-menu__item {
            text-align: start;

            &:before {
              inset-block-start: 0.938rem;
              inset-inline-start: 0.75rem;
            }
          }
        }

        .side-menu__angle {
          display: block;
          inset-inline-end: 1rem;
          inset-block-start: 0.65rem;
        }
      }
    }
  }
}
[data-nav-layout="horizontal"][data-nav-style="icon-click"] {
  .mega-menu {
    columns: 1;
  }
   .app-sidebar .slide.has-sub .slide-menu.child1{
    padding: 0px;
   }
}
[data-nav-layout="vertical"][data-nav-style="icon-click"] {
  @media (min-width: 992px) {
    &[data-toggled="icon-click-closed"] {
      .app-sidebar .main-menu {
        > .slide {
          padding: 0 1.2rem;
        }
      }
      .page-header-breadcrumb {
        padding-inline-start: 6rem;
      }
      &[data-theme-mode="dark"] {
        .app-sidebar {
          .main-sidebar-header {
            .header-logo {
              .toggle-dark {
                display: block;
              }
              .desktop-dark,
              .desktop-logo,
              .toggle-logo {
                display: none;
              }
            }
          }
        }
      }
    }
    .app-sidebar {
      position: absolute;
      .slide-menu {
        &.child1,
        &.child2,
        &.child3 {
          li.slide {
            padding-inline-start: 0;
            a {
              border-radius: 0;
            }
          }
        }
      }
      .slide.has-sub .slide-menu {
        border-start-end-radius: 0.4rem;
        border-end-end-radius: 0.4rem;
      }
    }
  }
}

@media (min-width: 992px) {
  [data-nav-style="icon-click"][data-nav-layout="vertical"] {
    &[data-header-position="rounded"] {
      .app-header.sticky-pin {
        margin-inline-start: 16rem;
        width: calc(100% - 17rem);
        padding-inline-start: 0;
      }
      &[data-toggled="icon-click-closed"] {
        .app-header.sticky-pin {
          margin-inline-start: 6rem;
          width: calc(100% - 7rem);
        }
      }
    }
  }
}
