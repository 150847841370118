/* Sidemenu Layout Syles */
@media (max-width: 991.98px) {
  .sidemenu-layout-styles {
    display: none;
  }
}

/* Sidemenu Layout Syles */

/* Respoonsive Logo Styles */
@media (max-width: 991.98px) {
  .app-header .horizontal-logo .header-logo img {
    height: 2rem;
    line-height: 2rem;
  }
}

/* Respoonsive Logo Styles */

/* Dark Mode */

//dark mode styles start
[data-theme-mode="dark"] {
  .app-sidebar .main-sidebar-header {
    .header-logo {
      .desktop-dark {
        display: block;
      }

      .desktop-logo,
      .toggle-logo,
      .toggle-dark {
        display: none;
      }
    }
  }

  .btn-close {
    filter: invert(1);
  }

  .alert {
    .btn-close {
      filter: unset;
    }
  }

  .dropdown-menu {
    box-shadow: 0 1rem 1.125rem rgba(255, 255, 255, 0.15);
  }

  button.gridjs-sort,
  button.gridjs-sort-desc,
  button.gridjs-sort-asc {
    filter: invert(1);
  }

  .choices__list--dropdown,
  .choices__list[aria-expanded] {
    box-shadow: rgba(10, 10, 10, 0.2) 0 0.5rem 1.5rem !important;
  }
  .main-chat-area {
    .chat-content {
      background-image: url("../images/media/chatdark.png") !important;
    }
  }
  *::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 0.1);
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .card.custom-card {
    box-shadow: 0 0.15rem 0.1rem rgba(255, 255, 255, 0.04);
  }
}

//dark mode styles end

/* Dark Mode */

/* Responsive Menu Styles */

// responsive header logo styles start
[data-header-styles="dark"],
[data-header-styles="color"],
[data-header-styles="gradient"],
[data-header-styles="transparent"][data-theme-mode="dark"] {
  @media (max-width: 991.98px) {
    .app-header .horizontal-logo .header-logo {
      .desktop-logo,
      .desktop-dark,
      .toggle-logo {
        display: none;
      }

      .toggle-dark {
        display: block;
      }
    }
  }
}

[data-header-styles="light"],
[data-header-styles="transparent"][data-theme-mode="light"] {
  @media (max-width: 991.98px) {
    .app-header .horizontal-logo .header-logo {
      .toggle-logo {
        display: block;
      }

      .desktop-logo,
      .toggle-dark,
      .desktop-dark {
        display: none;
      }
    }
  }
}

// responsive header logo styles end

// toggle close start
[data-toggled="close"] {
  @media (max-width: 991.98px) {
    .app-sidebar {
      // transition: all 300ms ease;
      transform: translateX(-15rem);
    }

    .app-header {
      padding-inline-start: 0rem;

      .horizontal-logo {
        display: block;
      }
    }

    &[dir="rtl"] {
      .app-sidebar {
        // transition: all 300ms ease;
        transform: translateX(15rem);
      }
    }
  }
}

// toggle close end

//toggle open start
[data-toggled="open"] {
  @media (max-width: 991.98px) {
    .app-sidebar {
      // transition: all 300ms ease;
      transform: translateX(0rem);
    }

    .app-header {
      padding-inline-start: 0rem;

      .horizontal-logo {
        display: block;
      }
    }
  }
}

//toggle open end

/* Responsive Menu Styles */

/* Page Styles */

// classic start
[data-page-style="classic"] {
  body {
    background-color: $custom-white;
  }

  .card.custom-card {
    box-shadow: none;
    border: 1px solid $default-border;
  }

  .app-sidebar,
  .footer,
  .app-header,
  .app-sidebar .main-sidebar {
    box-shadow: none;
  }

  &[data-nav-layout="horizontal"] {
    .app-sidebar {
      border-block-end: 1px solid $default-border;
    }
  }
}

// page styles classic end

/* Page Styles */

/* Layout Width Styles */

// boxed start
[data-width="boxed"] {
  @media (min-width: 1400px) {
    body {
      background-color: $default-background;
    }

    .page {
      width: 1400px !important;
      margin: 0 auto;
      background-color: $default-body-color;
      position: relative;
      box-shadow: 0 0 1rem $black-1;

      .app-header {
        width: 1400px;
        margin: 0 auto;
      }

      .app-sidebar {
        inset-inline-start: auto;
      }
    }

    &[data-vertical-style="overlay"]{
      .app-sidebar {
        overflow: hidden;
      }

    }
    &[data-nav-layout="horizontal"] {
      .app-sidebar {
        width: 1400px !important;
      }
    }

    &[data-page-style="classic"] {
      .page {
        background-color: $custom-white;
      }

      .card {
        box-shadow: none;
      }
    }

    &[data-theme-mode="dark"] {
      --default-body-bg-color: rgba(var(--body-bg-rgb), 0.85);

      body {
        background-color: rgba(var(--body-bg-rgb), 0.6);
      }
    }

    &[data-vertical-style="detached"] {
      .page {
        .app-header {
          width: 100%;
        }

        width: 1400px;
        box-shadow: none;
        padding-inline-start: 1.25rem;
      }

      &[data-toggled="detached-close"] {
        .app-content {
          margin-inline-start: 5rem !important;
        }
      }

      .app-header .main-header-container {
        width: 1400px;
      }

      .app-content {
        margin-inline-start: 15rem !important;
      }
    }

    &[data-vertical-style="doublemenu"] {
      .app-sidebar .main-sidebar {
        height: 100%;
      }

      .app-sidebar .simplebar-content-wrapper {
        position: initial;
      }

      .app-sidebar .simplebar-mask {
        position: inherit;
      }
    }
    &[data-header-position="rounded"] {
      &[data-nav-layout="vertical"] {
        .page {
          .app-header {
            .main-header-container {
              width: 100%;
            }
          }
        }
        &[data-nav-style="menu-click"] {
          .page {
            .app-header.sticky-pin {
              margin-inline-start: 32rem;
              width: calc(100% - 49rem);
            }
          }
          &[data-toggled="menu-click-closed"] {
            .page {
              .app-header.sticky-pin {
                margin-inline-start: 26rem;
                width: calc(100% - 43rem);
              }
            }
          }
        }
        &[data-nav-style="menu-hover"] {
          .page {
            .app-header.sticky-pin {
              margin-inline-start: 32rem;
              width: calc(100% - 49rem);
            }
          }
          &[data-toggled="menu-hover-closed"] {
            .page {
              .app-header.sticky-pin {
                margin-inline-start: 26rem;
                width: calc(100% - 43rem);
              }
            }
          }
        }
        &[data-nav-style="icon-click"] {
          .page {
            .app-header.sticky-pin {
              margin-inline-start: 32rem;
              width: calc(100% - 49rem);
            }
          }
          &[data-toggled="icon-click-closed"] {
            .page {
              .app-header.sticky-pin {
                margin-inline-start: 22rem;
                width: calc(100% - 39rem);
              }
            }
          }
        }
        &[data-nav-style="icon-hover"] {
          .page {
            .app-header.sticky-pin {
              margin-inline-start: 32rem;
              width: calc(100% - 49rem);
            }
          }
          &[data-toggled="icon-hover-closed"] {
            .page {
              .app-header.sticky-pin {
                margin-inline-start: 22rem;
                width: calc(100% - 39rem);
              }
            }
          }
        }
        &[data-vertical-style="detached"] {
          .page {
            .app-header.sticky-pin {
              margin-inline-start: 33rem;
              width: calc(100% - 50rem);
            }
          }
          &[data-toggled="detached-close"]:not([data-icon-overlay="open"]) {
            .page {
              .app-header.sticky-pin {
                margin-inline-start: 23rem;
                width: calc(100% - 40rem);
              }
            }
          }
        }
        &[data-vertical-style="overlay"] {
          .page {
            .app-header.sticky-pin {
              margin-inline-start: 32rem;
              width: calc(100% - 49rem);
            }
          }
          &[data-toggled="icon-overlay-close"]:not([data-icon-overlay="open"]) {
            .page {
              .app-header.sticky-pin {
                margin-inline-start: 22rem;
                width: calc(100% - 39rem);
              }
            }
          }
        }
        &[data-vertical-style="closed"] {
          .page {
            .app-header.sticky-pin {
              margin-inline-start: 32rem;
              width: calc(100% - 49rem);
            }
          }
          &[data-toggled="close-menu-close"] {
            .page {
              .app-header.sticky-pin {
                margin-inline-start: 17rem;
                width: calc(100% - 34rem);
              }
            }
          }
        }
        &[data-vertical-style="icontext"] {
          .page {
            .app-header.sticky-pin {
              margin-inline-start: 32rem;
              width: calc(100% - 49rem);
            }
          }
          &[data-toggled="icon-text-close"]:not([data-icon-text="open"]) {
            .page {
              .app-header.sticky-pin {
                margin-inline-start: 26rem;
                width: calc(100% - 43rem);
              }
            }
          }
        }
        &[data-vertical-style="doublemenu"] {
          &[data-toggled="double-menu-close"] {
            .page {
              .app-header.sticky-pin {
                margin-inline-start: 22rem;
                width: calc(100% - 39rem);
              }
            }
          }
          &[data-toggled="double-menu-open"] {
            .page {
              .app-header.sticky-pin {
                margin-inline-start: 34rem;
                width: calc(100% - 51rem);
              }
            }
          }
        }
      }
    }

    .row-cols-lg-5 {
      .col {
        flex: 0 0 auto;
        width: 33.3333333333%;
      }
    }
    .ecommerce-cards.row-cols-xxl-5 > * {
      width: 33.3333333333%;
    }
    .total-mails {
      min-width: 40rem;
      max-width: 40rem;
    }
  }
}
// boxed end

/* Layout Width Styles */

/* Menu Positions Styles */

//scrollable start
[data-menu-position="scrollable"] {
  &[data-nav-layout="vertical"] {
    .app-sidebar {
      position: absolute;
      height: 100%;

      .main-sidebar {
        height: 90%;
      }
    }
  }
  &[data-vertical-style="detached"] .app-sidebar {
    height: 95%;
  }
  &[data-nav-layout="horizontal"] {
    .app-sidebar {
      position: relative;
      z-index: 102;
    }
    .app-content {
      margin-block-start: 4rem;
    }
    .page-header-breadcrumb {
      top: 3.7rem;
    }

    &[data-header-position="scrollable"] {
      .app-sidebar {
        position: relative;
      }

      .app-header {
        position: relative;
      }

      .page-header-breadcrumb {
        top: 0;
      }
    }
  }

  @media (max-width: "991.98px") {
    &[data-nav-layout="horizontal"] {
      .app-sidebar {
        position: absolute;
      }

      .main-content {
        margin-block-start: 0;
      }

      .main-sidebar {
        height: 100%;
      }
    }
  }

  @media (min-width: 992px) {
    .app-content {
      min-height: calc(100vh - 3.5rem);
    }
  }
}

//scrollable end

/* Menu Positions Styles*/

/* Header Positions Styles */

//scrollable start
[data-header-position="scrollable"] {
  &[data-nav-layout="vertical"] {
    .app-header {
      position: absolute;
    }
  }

  &[data-nav-layout="horizontal"] {
    .app-sidebar {
      position: relative;
      top: 0;
      inset-block-start: 0;

      &.sticky {
        position: sticky;
        top: 0;
      }
    }
    .page-header-breadcrumb {
      top: 0;
    }

    .app-content {
      margin-block-start: 0;
    }

    .app-header {
      position: relative;
    }

    &[data-menu-position="scrollable"] {
      .app-sidebar {
        position: relative;
        inset-block-start: 0;
      }

      .app-header {
        position: relative;
      }
    }

    &[data-width="boxed"] {
      .app-header {
        margin: 0;
      }
    }
  }

  @media (max-width: "991.98px") {
    &[data-nav-layout="horizontal"] {
      .app-sidebar {
        position: absolute !important;
        z-index: 102;
      }

      .main-content {
        padding-block-start: 0;
      }

      &[data-menu-position="fixed"] {
        .app-sidebar {
          position: fixed !important;

          .main-sidebar {
            margin-block-start: 3.72rem;
          }

          &.sticky {
            .main-sidebar {
              margin-block-start: 0;
            }
          }
        }
      }
    }

    &[data-nav-layout="vertical"] {
      .app-sidebar.sticky {
        inset-block-start: 0;
      }
    }
  }
}

[data-header-position="rounded"] {
  @media (min-width:992px) {
    &[data-nav-layout="vertical"] {
      .app-header.sticky-pin {
        position: fixed;
        margin-inline-start: 18rem;
        width: calc(100% - 19rem);
        transition: 0.2s ease-in;
        margin-block-start: 15px;
        border-radius: 50px;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
        .main-header-container {
          padding-block-start: 0;
          padding-inline-start: 1.5rem;
          .header-content-right {
            background: transparent;
            margin-block-start: 0;
            padding-block-end: 0;
            .header-link-icon {
              margin-block-end: 0;
            }
            #mainHeaderProfile {
              margin-block-start: 0;
            }
          }
        }
      }
    }
  }
}

//scrollable end

/* Header Positions Styles*/

/* Menu Colors Styles*/

//menu-light start
[data-menu-styles="light"] {
  --menu-bg: #fff;
  --menu-prime-color: #536485;
  --menu-border-color: #f3f3f3;

  &[data-nav-layout="vertical"] {
    .app-sidebar .main-sidebar-header .header-logo {
      .desktop-logo {
        display: block;
      }

      .desktop-dark,
      .toggle-dark,
      .toggle-logo {
        display: none;
      }
    }
  }

  .app-sidebar {
    .side-menu__item {
      &.active,
      &:hover {
        background-color: transparent;
        color: $primary;
        .side-menu__label,
        .side-menu__angle {
          color: $primary;
        }
      }
    }
    .slide-menu {
      &.child1,
      &.child2,
      &.child3 {
        .side-menu__item {
          &.active,
          &:hover {
            color: $primary !important;
            &::before {
              border-color: $primary;
            }
          }
        }
      }
    }
  }

  .side-menu__label1 a {
    color: #222528;
    font-weight: 600;
  }

  .side-menu__label1 {
    border-block-end: 1px solid #f3f3f3 !important;
  }

  @media (min-width: 992px) {
    &[data-vertical-style="doublemenu"] .slide.has-sub .slide-menu {
      border-inline-end: 1px solid #f3f3f3;
    }
  }
}

//menu-light end

//menu-dark start
[data-menu-styles="dark"] {
  --menu-bg: #{var(--custom-white)};
  --menu-prime-color: rgb(171, 184, 199);
  --menu-prime-icon-color: rgb(130, 145, 164);
  --menu-border-color: rgba(255, 255, 255, 0.1);

  &[data-nav-layout="vertical"] {
    .app-sidebar .main-sidebar-header .header-logo {
      .desktop-dark {
        display: block;
      }

      .desktop-logo,
      .toggle-dark,
      .toggle-logo {
        display: none;
      }
    }

    &[data-vertical-style="overlay"][data-toggled="icon-overlay-close"] {
      .app-sidebar .main-sidebar-header .header-logo {
        .toggle-dark {
          display: block;
        }

        .desktop-logo,
        .desktop-dark,
        .toggle-logo {
          display: none;
        }
      }

      &[data-icon-overlay="open"] {
        .app-sidebar .main-sidebar-header .header-logo {
          .desktop-dark {
            display: block;
          }

          .desktop-logo,
          .toggle-dark,
          .toggle-logo {
            display: none;
          }
        }
      }
    }
    &[data-vertical-style="icontext"][data-toggled="icon-text-close"] {
      @media (min-width: 992px) {
        &:not([data-icon-text="open"]) {
          .app-sidebar {
            .main-sidebar-header {
              width: 9rem;
              .header-logo {
                .desktop-logo,
                .toggle-logo,
                .desktop-dark {
                  display: none;
                }
                .toggle-dark {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  .side-menu__label1 a {
    color: rgba(255, 255, 255);
    font-weight: normal;
  }

  .side-menu__label1 {
    border-block-end: 1px solid rgba(255, 255, 255, 0.1) !important;
  }

  .app-sidebar {
    .side-menu__item {
      &.active,
      &:hover {
        background-color: transparent;
        color: $white;
        .side-menu__label,
        .side-menu__angle {
          color: $white;
          fill: $white;
        }
        .side-menu__icon {
          color: $white;
          fill: $white;
        }
      }
    }
    .slide-menu {
      &.child1,
      &.child2,
      &.child3 {
        .side-menu__item {
          &.active,
          &:hover {
            color: $white !important;
            &::before {
              border-color: $white;
            }
          }
        }
      }
    }
  }

  &[data-theme-mode="light"] {
    --menu-bg: #1c2c3f;

    &[data-bg-img="bgimg1"] {
      --menu-bg: url(../images/menu-bg-images/bg-img1.jpg);
    }

    &[data-bg-img="bgimg2"] {
      --menu-bg: url(../images/menu-bg-images/bg-img2.jpg);
    }

    &[data-bg-img="bgimg3"] {
      --menu-bg: url(../images/menu-bg-images/bg-img3.jpg);
    }

    &[data-bg-img="bgimg4"] {
      --menu-bg: url(../images/menu-bg-images/bg-img4.jpg);
    }

    &[data-bg-img="bgimg5"] {
      --menu-bg: url(../images/menu-bg-images/bg-img5.jpg);
    }
  }

  @media (min-width: 992px) {
    &[data-nav-layout="horizontal"] .main-menu-container .slide-left,
    &[data-nav-layout="horizontal"] .main-menu-container .slide-right {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(30px);
      border: 0;

      svg {
        fill: #000;
      }
    }

    &[data-vertical-style="doublemenu"] .slide.has-sub .slide-menu {
      border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}
//menu-dark end

//menu-gradient start
[data-menu-styles="gradient"] {
  --menu-prime-color: rgba(255,255,255,0.8);
  --menu-prime-icon-color: rgba(255,255,255,0.8);
  --menu-border-color: rgba(255, 255, 255, 0.1);

  .app-sidebar {
    background: linear-gradient(to right, #4776e6 0%, var(--primary-color)100%);
  }
  @media (min-width:"992px") {
    &[data-nav-layout="horizontal"] {
      .app-header,.app-sidebar {
        border-bottom: 1px solid rgba(255,255,255,0.1);
      }
      .app-sidebar .slide-menu {
        &.child1,&.child2,&.child3 {
          background: $custom-white !important;
          .side-menu__item {
            color: $default-text-color !important; 
            &::before {
              border: 1px solid $default-text-color !important;
            }
            .side-menu__angle {
              color: $default-text-color !important; 
            }
            &:hover {
              color: $primary !important;
              .side-menu__angle {
                color: $primary !important; 
              }
              &::before {
                border: 1px solid $primary !important;
              }
            }
            &.active {
              color: $primary !important;
              &::before {
                border: 1px solid $primary !important;
              }
              .side-menu__angle {
                color: $primary !important; 
              }
            }
          }
        }
      }
    }
  }
  &[data-nav-layout="vertical"] {
    .app-sidebar .main-sidebar-header .header-logo {
      .desktop-dark {
        display: block;
      }

      .desktop-logo,
      .toggle-dark,
      .toggle-logo {
        display: none;
      }
    }

    &[data-vertical-style="overlay"][data-toggled="icon-overlay-close"] {
      .app-sidebar .main-sidebar-header .header-logo {
        .toggle-dark {
          display: block;
        }

        .desktop-logo,
        .desktop-dark,
        .toggle-logo {
          display: none;
        }
      }

      &[data-icon-overlay="open"] {
        .app-sidebar .main-sidebar-header .header-logo {
          .desktop-dark {
            display: block;
          }

          .desktop-logo,
          .toggle-dark,
          .toggle-logo {
            display: none;
          }
        }
      }
    }
    &[data-vertical-style="icontext"][data-toggled="icon-text-close"] {
      @media (min-width: 992px) {
        &:not([data-icon-text="open"]) {
          .app-sidebar {
            .main-sidebar-header {
              width: 9rem;
              .header-logo {
                .desktop-logo,
                .toggle-logo,
                .desktop-dark {
                  display: none;
                }
                .toggle-dark {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  .side-menu__label1 a {
    color: $default-text-color;
    font-weight: normal;
  }

  .side-menu__label1 {
    border-block-end: 1px solid $default-border !important;
  }

  .app-sidebar {
    .side-menu__item {
      &.active,
      &:hover {
        background-color: transparent;
        color: $white;
        .side-menu__label,
        .side-menu__angle {
          color: $white;
          fill: $white;
        }
        .side-menu__icon {
          color: $white;
          fill: $white;
        }
      }
    }
    .slide-menu {
      &.child1,
      &.child2,
      &.child3 {
        .side-menu__item {
          &.active,
          &:hover {
            color: $white !important;
            &::before {
              border-color: $white;
            }
          }
        }
      }
    }
  }

  &[data-theme-mode="light"] {
    --menu-bg: linear-gradient(to right, #4776e6 0%, var(--primary-color)100%);

    &[data-bg-img="bgimg1"] {
      --menu-bg: url(../images/menu-bg-images/bg-img1.jpg);
    }

    &[data-bg-img="bgimg2"] {
      --menu-bg: url(../images/menu-bg-images/bg-img2.jpg);
    }

    &[data-bg-img="bgimg3"] {
      --menu-bg: url(../images/menu-bg-images/bg-img3.jpg);
    }

    &[data-bg-img="bgimg4"] {
      --menu-bg: url(../images/menu-bg-images/bg-img4.jpg);
    }

    &[data-bg-img="bgimg5"] {
      --menu-bg: url(../images/menu-bg-images/bg-img5.jpg);
    }
  }

  @media (min-width: 992px) {
    &[data-nav-layout="horizontal"] .main-menu-container .slide-left,
    &[data-nav-layout="horizontal"] .main-menu-container .slide-right {
      background-color: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(30px);
      border: 0;

      svg {
        fill: #fff;
      }
    }

    &[data-vertical-style="doublemenu"] .slide.has-sub .slide-menu {
      border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}
//menu-gradient end

/* Header  Colors Styles*/
[data-default-header-styles] {
  .app-header {
    .main-header-container {
      padding-block-start: 0 !important;
      .header-content-right {
        background: transparent !important;
        padding: 0 10px !important;
        margin-block-start: 0 !important;
        .header-link-icon {
          margin-block-end: 0 !important;
        }
        .header-icon-badge {
          inset-block-start: 0.75rem !important;
        }
      }
    }
  }
  .card-background::before {
    display: none;
  }
}

//header-light start
[data-default-header-styles="light"] {
  --header-bg: #fff;
  --header-border-color: #f3f3f3;
  --header-prime-color: #536485;
  .app-header {
    border-block-end: 1px solid $header-border-color !important;
    .main-header-container {
      .header-link-icon:hover {
        background-color: #f3f6f8;
      }
    }
    .main-header-container .form-control {
      background: rgba(0, 0, 0, 0.05) !important;
      color: $default-text-color;
      box-shadow: none;
    }
    .main-header-center .btn {
      color: #1b2c3f;
    }
    .mainuserProfile {
      border-inline: 1px solid #f0f0f0;
      &:hover {
        background-color: #f3f6f8;
      }
    }
    #mainHeaderProfile .user-name {
      color: #1b2c3f;
    }

    .main-header-container .form-control {
      border-color: $header-border-color !important;
      color: #1b2c3f;
      &::placeholder {
        color: #1b2c3f;
      }
    }
  }
  &[data-theme-mode="light"] {
    .page-header-breadcrumb {
      background: transparent;
      h4 {
        color: #000 !important;
      }
      .breadcrumb-item {
        a {
          color: $primary !important;
        }
        &.active {
          color: $text-muted !important;
        }
      }
      .breadcrumb-item + .breadcrumb-item::before {
        color: $text-muted !important;
      }
    }
  }
  &[data-theme-mode="dark"] {
    .page-header-breadcrumb {
      background: transparent;
      h4 {
        color: #fff;
      }
      .breadcrumb-item {
        a {
          color: $primary !important;
        }
        &.active {
          color: $text-muted !important;
        }
      }
      .breadcrumb-item + .breadcrumb-item::before {
        color: $text-muted !important;
      }
    }
  }
}
//header-light start

//header-dark start
[data-default-header-styles="dark"] {
  --header-bg: #{var(--custom-white)};
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-prime-color: rgba(255, 255, 255, 0.6);
  &[data-nav-layout="horizontal"] {
    // .app-header {
    //   border-block-end: 0 !important;
    // }
    @media (min-width: 992px) {
      .header-element .horizontal-logo .header-logo {
        .desktop-dark {
          display: block;
        }

        .desktop-logo,
        .toggle-dark,
        .toggle-logo {
          display: none;
        }
      }
    }
  }
  .app-header {
    border-block-end: 1px solid $header-border-color !important;
    .main-header-container {
      .header-link-icon:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .main-header-center .btn {
      color: #fff;
    }
    .mainuserProfile {
      border-inline: 1px solid rgba(255, 255, 255, 0.15);
    }
    #mainHeaderProfile .user-name {
      color: #fff !important;
    }

    .main-header-container .form-control {
      border-color: $header-border-color !important;
      color: #fff;
      &::placeholder {
        color: #fff;
      }
    }
  }
  &[data-theme-mode="light"] {
    --header-bg: #1c2c3f;
    .page-header-breadcrumb {
      background: transparent;
      h4 {
        color: #000 !important;
      }
      .breadcrumb-item {
        a {
          color: $primary !important;
        }
        &.active {
          color: $text-muted !important;
        }
      }
      .breadcrumb-item + .breadcrumb-item::before {
        color: $text-muted !important;
      }
    }
  }
  &[data-theme-mode="dark"] {
    .page-header-breadcrumb {
      background: transparent;
      h4 {
        color: #fff;
      }
      .breadcrumb-item {
        a {
          color: $primary !important;
        }
        &.active {
          color: $text-muted !important;
        }
      }
      .breadcrumb-item + .breadcrumb-item::before {
        color: $text-muted !important;
      }
    }
  }
}
//header-dark start

//header-color start
[data-default-header-styles="color"] {
  --header-bg: var(--primary-color);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-prime-color: rgba(255, 255, 255, 0.7);
  &[data-nav-layout="horizontal"] {
    // .app-header {
    //   border-block-end: 0 !important;
    // }
    @media (min-width: 992px) {
      .header-element .horizontal-logo .header-logo {
        .desktop-dark {
          display: block;
        }

        .desktop-logo,
        .toggle-dark,
        .toggle-logo {
          display: none;
        }
      }
    }
  }
  .app-header {
    border-block-end: 1px solid $header-border-color !important;
    .main-header-container {
      .header-link-icon:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .main-header-center .btn {
      color: #fff;
    }
    .mainuserProfile {
      border-inline: 1px solid rgba(255, 255, 255, 0.15);
    }
    #mainHeaderProfile .user-name {
      color: #fff !important;
    }

    .main-header-container .form-control {
      border-color: $header-border-color !important;
      color: #fff;
      &::placeholder {
        color: #fff;
      }
    }
  }
  &[data-theme-mode="light"] {
    .page-header-breadcrumb {
      background: transparent;
      h4 {
        color: #000 !important;
      }
      .breadcrumb-item {
        a {
          color: $primary !important;
        }
        &.active {
          color: $text-muted !important;
        }
      }
      .breadcrumb-item + .breadcrumb-item::before {
        color: $text-muted !important;
      }
    }
  }
  &[data-theme-mode="dark"] {
    .page-header-breadcrumb {
      background: transparent;
      h4 {
        color: #fff;
      }
      .breadcrumb-item {
        a {
          color: $primary !important;
        }
        &.active {
          color: $text-muted !important;
        }
      }
      .breadcrumb-item + .breadcrumb-item::before {
        color: $text-muted !important;
      }
    }
  }
}
//header-color start

//header-gradient start
[data-default-header-styles="gradient"] {
  --header-bg: linear-gradient(to right, #4776e6 0%, #{$primary}100%);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-prime-color: rgba(255, 255, 255, 0.7);
  &[data-nav-layout="horizontal"] {
    .app-header {
      border-block-end: 0 !important;
    }
    @media (min-width: 992px) {
      .header-element .horizontal-logo .header-logo {
        .desktop-dark {
          display: block;
        }

        .desktop-logo,
        .toggle-dark,
        .toggle-logo {
          display: none;
        }
      }
    }
  }
  .app-header {
    border-block-end: 1px solid $header-border-color !important;
    .main-header-container {
      .header-link-icon:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .main-header-center .btn {
      color: #fff;
    }
    .mainuserProfile {
      border-inline: 1px solid rgba(255, 255, 255, 0.15);
    }
    #mainHeaderProfile .user-name {
      color: #fff !important;
    }

    .main-header-container .form-control {
      border-color: $header-border-color !important;
      color: #fff;
      &::placeholder {
        color: #fff;
      }
    }
  }
  &[data-theme-mode="light"] {
    .page-header-breadcrumb {
      background: transparent;
      h4 {
        color: #000 !important;
      }
      .breadcrumb-item {
        a {
          color: $primary !important;
        }
        &.active {
          color: $text-muted !important;
        }
      }
      .breadcrumb-item + .breadcrumb-item::before {
        color: $text-muted !important;
      }
    }
  }
  &[data-theme-mode="dark"] {
    .page-header-breadcrumb {
      background: transparent;
      h4 {
        color: #fff;
      }
      .breadcrumb-item {
        a {
          color: $primary !important;
        }
        &.active {
          color: $text-muted !important;
        }
      }
      .breadcrumb-item + .breadcrumb-item::before {
        color: $text-muted !important;
      }
    }
  }
}
//header-gradient start

//header-transparent start
[data-default-header-styles="transparent"] {
  --header-bg: transparent;
  &[data-theme-mode="light"] {
    --header-border-color: #f3f3f3;
    --header-prime-color: #536485;
    &[data-nav-layout="horizontal"] {
      .app-header {
        border-block-end: 0 !important;
      }
    }

    .app-header {
      &.sticky-pin {
        background: rgb(var(--body-bg-rgb));
      }
      border-block-end: 1px solid $header-border-color !important;
      .main-header-container {
        .header-link-icon:hover {
          background-color: #f3f6f8;
        }
      }
      .main-header-center .btn {
        color: #1b2c3f;
      }
      .mainuserProfile {
        border-inline: 1px solid #f0f0f0;
        &:hover {
          background-color: #f3f6f8;
        }
      }
      #mainHeaderProfile .user-name {
        color: #1b2c3f;
      }

      .main-header-container .form-control {
        border-color: $header-border-color !important;
        color: #1b2c3f;
        &::placeholder {
          color: #1b2c3f;
        }
      }
    }
    .page-header-breadcrumb {
      background: transparent;
      h4 {
        color: #000;
      }
      .breadcrumb-item {
        a {
          color: $primary !important;
        }
        &.active {
          color: $text-muted !important;
        }
      }
      .breadcrumb-item + .breadcrumb-item::before {
        color: $text-muted !important;
      }
    }
  }
  &[data-theme-mode="dark"] {
    --header-border-color: rgba(255, 255, 255, 0.1);
    --header-prime-color: rgba(255, 255, 255, 0.7);
    &[data-nav-layout="horizontal"] {
      @media (min-width: 992px) {
        .header-element .horizontal-logo .header-logo {
          .desktop-dark {
            display: block;
          }

          .desktop-logo,
          .toggle-dark,
          .toggle-logo {
            display: none;
          }
        }
      }
    }
    .app-header {
      &.sticky-pin {
        background: rgb(var(--body-bg-rgb));
      }
      border-block-end: 1px solid $header-border-color !important;
      .main-header-container {
        .header-link-icon:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
      .main-header-center .btn {
        color: #fff;
      }
      .mainuserProfile {
        border-inline: 1px solid rgba(255, 255, 255, 0.15);
      }
      #mainHeaderProfile .user-name {
        color: #fff;
      }

      .main-header-container .form-control {
        border-color: $header-border-color !important;
        color: #fff;
        &::placeholder {
          color: #fff;
        }
      }
    }
    .page-header-breadcrumb {
      background: transparent;
      h4 {
        color: #fff;
      }
      .breadcrumb-item {
        a {
          color: $primary !important;
        }
        &.active {
          color: $text-muted !important;
        }
      }
      .breadcrumb-item + .breadcrumb-item::before {
        color: $text-muted !important;
      }
    }
  }
}
//header-transparent start

/* Header Colors Styles*/

/* Header & Breadcrum Colors Styles*/

//header-light start
[data-header-styles="light"] {
  --header-bg: #fff;
  --header-border-color: #f3f3f3;
  --header-prime-color: #536485;

  .main-header-container .header-link-icon:hover {
    background-color: #f3f6f8;
  }
  .mainuserProfile {
    border-inline: 1px solid $default-border;
  }
  #mainHeaderProfile .user-name {
    color: $default-body-color;
  }
  &:not([data-nav-layout="horizontal"]) {
    .app-header .main-header-container .header-content-right,
    .app-header .main-header-container .form-control {
      background: rgba(0, 0, 0, 0.05) !important;
    }
  }
}
//header-light start

//header-dark start
[data-header-styles="dark"] {
  --header-bg: #{var(--custom-white)};
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-prime-color: rgba(255, 255, 255, 0.6);

  &[data-nav-layout="horizontal"] {
    @media (min-width: 992px) {
      .header-element .horizontal-logo .header-logo {
        .desktop-dark {
          display: block;
        }

        .desktop-logo,
        .toggle-dark,
        .toggle-logo {
          display: none;
        }
      }
    }
  }

  .main-header-container {
    .header-link:hover,
    .header-link.show {
      .header-link-icon {
        fill: $white;
      }
    }

    .header-link-icon:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &[data-theme-mode="light"] {
    --header-bg: #1c2c3f;
  }
  .mainuserProfile {
    border-inline: 1px solid rgba(255, 255, 255, 0.15);
  }
}

//header-dark start

//header-color start
[data-header-styles="color"] {
  --header-bg: #{$primary};
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-prime-color: rgba(255, 255, 255, 0.7);

  &[data-nav-layout="horizontal"] {
    @media (min-width: 992px) {
      .header-element .horizontal-logo .header-logo {
        .desktop-dark {
          display: block;
        }

        .desktop-logo,
        .toggle-dark,
        .toggle-logo {
          display: none;
        }
      }
    }
  }
  .mainuserProfile {
    border-inline: 1px solid rgba(255, 255, 255, 0.1);
  }
  .main-header-container {
    .header-link:hover,
    .header-link.show {
      .header-link-icon {
        fill: $white;
      }
    }

    .header-link-icon:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}

//header-color start

//header-gradient start
[data-header-styles="gradient"] {
  --header-bg: linear-gradient(to right, #4776e6 0%, #{$primary}100%);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-prime-color: rgba(255, 255, 255, 0.7);

  &[data-nav-layout="horizontal"] {
    @media (min-width: 992px) {
      .header-element .horizontal-logo .header-logo {
        .desktop-dark {
          display: block;
        }

        .desktop-logo,
        .toggle-dark,
        .toggle-logo {
          display: none;
        }
      }
    }
  }
  .mainuserProfile {
    border-inline: 1px solid rgba(255, 255, 255, 0.15);
  }

  .main-header-container {
    .header-link:hover,
    .header-link.show {
      .header-link-icon {
        fill: $white;
      }
    }

    .header-link-icon:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

//header-gradient start

//header-transparent start
[data-header-styles="transparent"] {
  --header-bg: transparent;
  .app-header {
    .main-header-container {
      .form-control {
        border-radius: 50px;
        box-shadow: $box-shadow;
        border-color: rgba(255, 255, 255, 0.05);
        line-height: 20;
        padding: 0.3rem 1rem;
        &::placeholder {
          color: $default-text-color !important;
        }
      }
      .main-header-center .btn {
        color: $default-text-color !important;
      }
    }
  }
  .page-header-breadcrumb {
    color: $default-text-color !important;
    .breadcrumb-item {
      a {
        color: $black-6 !important;
      }
      &.active {
        color: $default-text-color !important;
      }
    }
    .breadcrumb-item + .breadcrumb-item::before {
      color: $black-6 !important;
    }
  }

  .main-header-container .header-link-icon:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &[data-theme-mode="light"] {
    --header-border-color: rgba(0, 0, 0, 0.07);
    &:not([data-nav-layout="horizontal"]) {
      .app-header .main-header-container .form-control {
        background: rgba(0, 0, 0, 0.05) !important;
      }
    }
    .app-header {
      &.sticky-pin {
        --header-bg: #fff;
      }
    }
  }

  &[data-theme-mode="dark"] {
    --header-prime-color: rgba(255, 255, 255, 0.6);
    --header-border-color: rgba(255, 255, 255, 0.1);

    .main-header-container .header-link-icon:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
    .app-header {
      &.sticky-pin {
        --header-bg: rgb(38, 44, 60);
      }
    }
  }

  &[data-nav-layout="horizontal"] {
    @media (min-width: 992px) {
      &[data-theme-mode="light"] {
        .header-element .horizontal-logo .header-logo {
          .desktop-dark,
          .toggle-logo,
          .toggle-dark {
            display: none;
          }

          .desktop-logo {
            display: block;
          }
        }
      }

      &[data-theme-mode="dark"] {
        .header-element .horizontal-logo .header-logo {
          .desktop-dark {
            display: block;
          }

          .desktop-logo,
          .toggle-logo,
          .toggle-dark {
            display: none;
          }
        }
      }
    }
  }
}

//header-gradient start

/* Header & Breadcrum Colors Styles*/

/* Menu Background Image Styles */

.bg-img {
  @mixin slide-menu-bg($color) {
    &[data-nav-layout="horizontal"] {
      ul.slide-menu {
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;

        &:before {
          content: "";
          position: absolute;
          inset-inline-start: 0;
          top: 0;
          inset-inline-end: 0;
          bottom: 0;
          border-radius: 0 0 0.25rem 0.25rem;
          opacity: 0.9;
          background: rgb(var(--body-bg-rgb));
        }
      }

      &[data-menu-styles="color"],
      &[data-menu-styles="dark"],
      &[data-menu-styles="gradient"] {
        ul.slide-menu:before {
          display: none;
        }
      }

      &[data-theme-mode="dark"] {
        &[data-menu-styles="light"] {
          ul.slide-menu:before {
            background: rgb(245, 246, 247);
          }
        }

        &[data-menu-styles="dark"] {
          ul.slide-menu:before {
            display: block;
          }
        }
      }

      &[data-nav-layout="horizontal"][data-menu-styles="dark"][data-theme-mode="light"] {
        .app-sidebar .slide.has-sub .slide-menu {
          background: #011626;
        }
      }
    }

    &[data-nav-layout="vertical"] {
      &[data-menu-styles="light"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
      &[data-menu-styles="light"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"] {
        .app-sidebar .main-sidebar-header {
          background-color: rgb(255, 255, 255);
        }
      }

      &[data-menu-styles="color"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
      &[data-menu-styles="color"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"] {
        .app-sidebar .main-sidebar-header {
          background-color: $primary;
        }
      }

      &[data-menu-styles="gradient"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
      &[data-menu-styles="gradient"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"] {
        .app-sidebar .main-sidebar-header {
          background: linear-gradient(
            to right,
            var(--primary-color) 0,
            rgb(var(--secondary-rgb)) 100%
          );
        }
      }

      &[data-menu-styles="dark"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
      &[data-menu-styles="dark"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"] {
        .app-sidebar .main-sidebar-header {
          background-color: $light;
        }
      }

      &[data-menu-styles="transparent"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
      &[data-menu-styles="transparent"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"] {
        .app-sidebar .main-sidebar-header {
          background-color: rgb(var(--body-bg-rgb));
        }
      }
    }

    &[data-theme-mode="dark"] {
      &[data-menu-styles="transparent"] {
        .app-sidebar {
          .slide.has-sub {
            .slide-menu {
              &.child1,
              &.child2,
              &.child3 {
                background-color: transparent !important;
              }
            }
          }
        }

        &[data-nav-style="menu-click"][data-toggled="menu-click-closed"],
        &[data-nav-style="menu-hover"][data-toggled="menu-hover-closed"],
        &[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"],
        &[data-nav-style="icon-click"][data-toggled="icon-click-closed"] {
          .app-sidebar {
            .slide.has-sub {
              .slide-menu {
                &.child1,
                &.child2,
                &.child3 {
                  background-color: $custom-white !important;
                }
              }
            }
          }
        }
      }
    }

    &[data-theme-mode="light"] {
      &[data-menu-styles="dark"] {
        .app-sidebar:before {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }

  .app-sidebar:before {
    background-color: rgba(var(--body-bg-rgb), 0.8);
  }

  @include slide-menu-bg($white);

  &[data-vertical-style="doublemenu"] {
    .slide.has-sub .slide-menu {
      background-color: $white;
      background-image: none;
    }
  }

  &[data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
  &[data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"],
  &[data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"],
  &[data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] {
    .app-sidebar {
      .slide.has-sub .slide-menu {
        background-color: $white;
        background-image: none;
      }
    }
  }

  &[data-menu-styles="light"] {
    .app-sidebar:before {
      background-color: rgba(255, 255, 255, 0.85);
    }
  }

  &[data-menu-styles="dark"] {
    @include slide-menu-bg(rgba(0, 0, 0, 0.9));

    &[data-vertical-style="doublemenu"] {
      .slide.has-sub .slide-menu {
        background-color: #1c2c3f;
        background-image: none;
      }
    }

    &[data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
    &[data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"],
    &[data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"],
    &[data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] {
      .app-sidebar {
        .slide.has-sub .slide-menu {
          background-color: $light;
          background-image: none;
        }
      }
    }
  }

  .app-sidebar {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-inline-end: 0;

    .main-sidebar-header {
      background: transparent;
    }

    &:before {
      content: "";
      position: absolute;
      inset-inline-start: 0;
      inset-block-start: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}

//bg-img1 start
[data-bg-img="bgimg1"] {
  --menu-bg: url(../images/menu-bg-images/bg-img1.jpg);
  @extend .bg-img;

  &[data-menu-styles="transparent"][data-theme-mode="dark"] {
    --menu-bg: url(../images/menu-bg-images/bg-img1.jpg);
  }
}

//bg-img1 end

//bg-img2 start
[data-bg-img="bgimg2"] {
  --menu-bg: url(../images/menu-bg-images/bg-img2.jpg);
  @extend .bg-img;

  &[data-menu-styles="transparent"][data-theme-mode="dark"] {
    --menu-bg: url(../images/menu-bg-images/bg-img2.jpg);
  }
}

//bg-img2 end

//bg-img3 start
[data-bg-img="bgimg3"] {
  --menu-bg: url(../images/menu-bg-images/bg-img3.jpg);
  @extend .bg-img;

  &[data-menu-styles="transparent"][data-theme-mode="dark"] {
    --menu-bg: url(../images/menu-bg-images/bg-img3.jpg);
  }
}

//bg-img3 end

//bg-img4 start
[data-bg-img="bgimg4"] {
  --menu-bg: url(../images/menu-bg-images/bg-img4.jpg);
  @extend .bg-img;

  &[data-menu-styles="transparent"][data-theme-mode="dark"] {
    --menu-bg: url(../images/menu-bg-images/bg-img4.jpg);
  }
}

//bg-img4 end

//bg-img5 start
[data-bg-img="bgimg5"] {
  --menu-bg: url(../images/menu-bg-images/bg-img5.jpg);
  @extend .bg-img;

  &[data-menu-styles="transparent"][data-theme-mode="dark"] {
    --menu-bg: url(../images/menu-bg-images/bg-img5.jpg);
  }
}

//bg-img5 end

/* Menu Background Image Styles */
/* Loader Styles */
[loader="disable"] {
  #loader {
    display: none;
  }
}
/* Loader Styles */

/* Start:: rtl */
[dir="rtl"] {
  .vertical-scroll {
    .ri-arrow-right-s-line:before {
      content: "\ea64";
    }
  }
  .btn-icon i {
    &.ti-chevron-right:before {
      content: "\ea60";
    }
  }
  .avatar {
    .fe-chevron-right:before {
      content: "\e92e";
    }
  }
  .alert.alert-dismissible {
    padding: 0.625rem 0.85rem 0.625rem 2.25rem;
  }
  .page-header-breadcrumb {
    .breadcrumb-item + .breadcrumb-item::before {
      content: var(--bs-breadcrumb-divider, "\ea60");
      font-family: "tabler-icons" !important;
    }
  }
  [type="email"],
  [type="number"],
  [type="tel"],
  [type="url"] {
    direction: rtl;
  }
  .fullcalendar-events-activity {
    li {
      padding: 0.25rem 2rem 0.25rem 1rem;
    }
  }
  .pricing-offer-details {
    transform: rotate(315deg);
  }
  @media screen and (min-width: 1200px) {
    #trademeter {
      margin-inline: -70px;
    }
  }
  .orders-arrow i::before {
    content: "\EA64";
  }
  .pagination-dynamic {
    .swiper-slide {
      .card {
        direction: rtl;
      }
    }
  }
  .input-group {
    .choices__inner {
      border-radius: $default-radius 0 0 $default-radius !important;
    }
  }

  .choices[data-type*="select-one"]::after {
    left: 0.7188rem;
    right: inherit;
  }

  .choices[data-type*="select-one"] .choices__button {
    left: 0;
    right: inherit;
    margin-left: 1.5625rem;
    margin-right: inherit;
  }

  &[data-theme-mode="dark"] {
    .choices[data-type*="select-one"] .choices__button {
      filter: invert(1);
    }
  }

  .choices[data-type*="select-multiple"] .choices__button,
  .choices[data-type*="text"] .choices__button {
    margin: 0 8px 0 -4px;
    padding-right: 1rem;
    padding-left: inherit;
    border-right: 0px solid #008fa1;
    border-left: 0;
  }
  .apexcharts-canvas .apexcharts-yaxis {
    .apexcharts-yaxis-texts-g {
      transform: translate(-1.75rem, 0);
    }
  }
  .flatpickr-months {
    .numInputWrapper span {
      left: 0 !important;
      right: inherit !important;
    }
  }
  .ql-tooltip.ql-editing {
    left: 0 !important;
    top: 0 !important;
  }

  .ql-bubble .ql-toolbar .ql-formats:first-child {
    margin-inline-end: 12px;
  }

  .ql-bubble .ql-toolbar .ql-formats {
    margin: 8px 0px 8px 12px;
  }
  .gridjs-pagination .gridjs-pages button:first-child {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: $default-radius !important;
    border-top-right-radius: $default-radius !important;
  }

  .gridjs-pagination .gridjs-pages button:last-child {
    border-bottom-left-radius: $default-radius !important;
    border-top-left-radius: $default-radius !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  div.dtr-modal div.dtr-modal-close {
    left: 6px;
    right: auto;
  }

  div.dataTables_wrapper div.dataTables_filter input {
    margin-right: 0.5rem;
    margin-left: 0;
  }

  table.table-bordered.dataTable th:first-child,
  table.table-bordered.dataTable th:first-child,
  table.table-bordered.dataTable td:first-child,
  table.table-bordered.dataTable td:first-child {
    border-right-width: 1px;
    border-left-width: 0;
  }

  table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable td:last-child,
  table.table-bordered.dataTable td:last-child {
    border-inline-start-width: 1px;
  }

  div.table-responsive
    > div.dataTables_wrapper
    > div.row
    > div[class^="col-"]:first-child {
    padding-inline-end: 0;
  }

  div.table-responsive
    > div.dataTables_wrapper
    > div.row
    > div[class^="col-"]:last-child {
    padding-inline-start: 0;
  }

  .dt-buttons {
    float: right !important;
  }
  .swal2-container
    .swal2-icon.swal2-success
    [class^="swal2-success-line"][class$="tip"] {
    inset-inline-start: 1.9125em;
  }
  .swiper {
    direction: ltr;
  }
  pre[class*="language-"] > code {
    border-left: 0;
    text-align: right;
  }
  &[data-nav-layout="horizontal"] {
    @media (min-width: 992px) {
      .main-menu-container {
        .slide-right,
        .slide-left {
          transform: rotate(180deg);
        }
      }
    }
  }
  .app-sidebar {
    .fe-chevron-right {
      transform: rotate(180deg);
    }
  }
  .sidemenu-toggle .header-link {
    .header-link-icon {
      transform: rotate(180deg);
    }
  }

  .ecommerce-more-link {
    &:before {
      inset-inline-end: -0.5rem;
    }
    &:after {
      inset-inline-end: -0.5rem;
    }
  }
  .page-link {
    .ri-arrow-right-s-line:before {
      content: "\ea64";
    }
    .ri-arrow-left-s-line:before {
      content: "\ea6e";
    }
    .bi-caret-left::before {
      content: "\f234";
    }
    .bi-caret-right::before {
      content: "\f230";
    }
  }
  @media (min-width: 992px) {
    body {
      overflow: auto !important;
      padding-left: 0px !important;
    }
  }
  
}
/* End:: rtl */
