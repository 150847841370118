/* Start::app-header */
.app-header {
  max-width: 100%;
  height: 4rem;
  z-index: 100;
  position: fixed;
  inset-block-start: 0;
  inset-inline: 0;
  background: $header-bg;
  border-block-end: 1px solid $header-border-color;
  #mainHeaderProfile {
    color: $header-prime-color;
  }
  .main-header-center {
    position: relative;
    padding-left: 0px;
    align-items: center;
  }
}
#mainHeaderProfile {
  padding: 1.4rem;
}
#mainHeaderProfile .user-name {
  color: #fff;
}
.app-header #mainHeaderProfile:hover {
  background-color: $white-1;
}
@media (max-width: 991px) {
  #mainHeaderProfile {
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .app-header {
    padding-inline-start: 15rem;
  }
  .main-header-center .btn {
    position: absolute;
    inset-block-start: 11px;
    inset-inline-end: 14px;
    background-color: transparent;
    height: 2.5rem;
    color: $white;
    transition: none;
    font-size: 16px;
    border: 0;
    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      border: 0;
    }
    &:active:focus {
      box-shadow: none;
      border: 0px;
      color: $white;
    }
  }
  .main-header-center .form-control {
    height: 2.5rem;
    background: rgba(255, 255, 255, 0.1);
    border: 0px;
    border-radius: 50px;
    margin-block-start: -16px;
    color: $white;
  }
  .main-header-center .form-control::placeholder {
    color: #fff;
  }
}
@media (min-width: 1165px) {
  .main-header-center .form-control {
    width: 350px;
  }
}
.header-icon-badge {
  position: absolute;
  padding: 0.15rem 0.25rem;
  inset-block-start: 0.75rem;
  inset-inline-end: 0.5rem;
  font-size: 0.625rem;
}
.related-app {
  text-align: center;
  border-radius: $default-radius;
  &:hover {
    background-color: $light;
  }
}
.header-profile-dropdown {
  min-width: 11rem;
}
/* End::app-header */
.main-header-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;

  .header-content-left,
  .header-content-right {
    display: flex;
    align-items: stretch;
  }

  .header-element {
    display: flex;
    align-items: stretch;
  }

  .header-link {
    display: flex;
    align-items: center;
    padding: 1.65rem 0.15rem;

    &:hover,
    &.show {
      .header-link-icon {
        fill: $header-prime-color;
        color: $header-prime-color;
      }
    }
  }

  .header-link-icon {
    width: 2.3rem;
    height: 2.3rem;
    font-size: 1.25rem;
    color: $header-prime-color;
    fill: $header-prime-color;
    transition: all ease 0.05s;
    position: relative;
    padding: 0.5rem;
    border-radius: 50%;

    &:hover {
      background-color: $black-1;
    }
  }

  .dropdown-toggle {
    text-decoration: none;

    &::after {
      content: none;
    }
  }

  .main-profile-user {
    .dropdown-menu {
      width: 13rem;
    }

    .dropdown-item {
      font-weight: 400;
      font-size: 0.813rem;
      color: $default-text-color;
      padding: 1.25rem 1rem;
      height: 2.375rem;
      display: flex;
      align-items: center;

      &:hover {
        color: $primary;

        i {
          color: $primary;
          opacity: 1;
        }
      }
    }
  }

  .main-header-dropdown {
    inset-block-start: -1px !important;
    border-radius: $default-radius;
    &.header-profile-dropdown {
      inset-block-start: -1px !important;
    }
    // inset-block-start: -1px !important;
    // border-radius: 0px 0px $default-radius $default-radius;
    &.header-shortcuts-dropdown {
      width: 22rem;
    }
    .dropdown-item {
      padding: 0.65rem 1rem;
      border-block-end: 1px solid $default-border;
      &:last-child {
        border-block-end: 0;
      }
    }
  }
  .cart-dropdown .main-header-dropdown {
    width: 22rem;
  }

  .notifications-dropdown .main-header-dropdown {
    width: 24rem;
  }
}
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(6rem);
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
    transform: translateY(6rem);
  }
}
.close-toggle {
  display: none;
}
.sidenav-toggled {
  .close-toggle {
    display: block;
  }
  .open-toggle {
    display: none;
  }
}
/* End::main-header-container */

/* Start::Header-dropdown */
.header-product-item {
  padding-inline-start: 0;
  display: flex;
  li {
    position: relative;
    list-style-type: none;
    font-size: 0.75rem;
    font-weight: 400;
    color: $text-muted;
    padding-inline-end: 0.938rem;
  }
  li:nth-child(2),
  li:nth-child(3),
  li:nth-child(4) {
    &:before {
      position: absolute;
      content: "";
      inset-inline-start: -0.438rem;
      width: 1px;
      inset-block-start: 0.25rem;
      height: 0.625rem;
      background-color: $default-border;
    }
  }
}
.header-cart-remove {
  margin-block-start: 0.125rem;
  .ti {
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.85rem;
    margin-inline-start: 0.75rem;
    color: $danger;
  }
  &:hover {
    .ti {
      background-color: rgba(var(--danger-rgb), 0.1);
    }
  }
}
/* End::Header-dropdown */

/* Start::header-search */
.headersearch {
  display: none;
}
.header-search {
  display: none;
}
.header-search.searchdrop {
  display: block;
}
.searchdrop {
  border-radius: 0.4rem;
  border: 0px solid var(--default-border);
  background-color: var(--custom-white);
  box-shadow: 0 0.4rem 0.5rem rgb(0 0 0 / 10%);
  position: relative;
  -webkit-margin-after: 1.5rem;
  margin-block-end: 1.5rem;
  width: 100%;
  // padding: 1.1rem;
  display: block;
  position: inherit;
  inset-block-start: 3px;
}
.search-app:hover a {
  color: $primary;
}
.search-tags {
  font-size: 0.75rem;
  color: $default-text-color;
  border: 1px solid $default-border;
  border-radius: 10rem;
  background-color: $light;
  padding: 0.125rem 0.55rem;
  line-height: 1.2rem;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  margin: 0.25rem 0;
  .tag-addon:last-child {
    border-radius: $default-radius;
  }
  a.tag-addon {
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    padding: 0 0.5rem;
    color: inherit;
    text-decoration: none;
    background: $light;
    margin-inline-end: -0.4rem;
    margin-inline-start: 0.5rem;
    text-align: center;
    min-width: 1.5rem;
    i {
      vertical-align: middle;
      margin: 0 -0.25rem;
      line-height: 1.5px;
      font-size: 0.813rem;
    }
  }
  i {
    font-size: 0.813rem;
  }
}
/* End::header-search */

/* Start::header-country-selector */
.country-selector {
  .header-link img {
    width: 1.25rem;
    height: 1.25rem;
  }
  .dropdown-menu {
    img {
      width: 1rem;
      height: 1rem;
    }
  }
}
/* End::header-country-selector */

/* Start:header dropdowns scroll */
#header-shortcut-scroll,
#header-notification-scroll,
#header-cart-items-scroll {
  max-height: 21.1rem;
}
/* End:header dropdowns scroll */

/* Start::header badge pulse */
.pulse {
  display: block;
  cursor: pointer;
  animation: pulse-info 2s infinite;
  &.pulse-info {
    box-shadow: 0 0 0 rgba(var(--info-rgb), 0.4);
  }
}
@-webkit-keyframes pulse-info {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(var(--info-rgb), 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(var(--info-rgb), 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(var(--info-rgb), 0);
  }
}
@keyframes pulse-info {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(var(--info-rgb), 0.4);
    box-shadow: 0 0 0 0 rgba(var(--info-rgb), 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(var(--info-rgb), 0);
    box-shadow: 0 0 0 10px rgba(var(--info-rgb), 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(var(--info-rgb), 0);
    box-shadow: 0 0 0 0 rgba(var(--info-rgb), 0);
  }
}
/* End::header badge pulse */

/* Start::Header theme-mode icon style */
[data-theme-mode="light"] {
  .layout-setting .dark-layout {
    display: none;
  }
  .layout-setting .light-layout {
    display: block;
  }
  &[data-header-styles="light"],
  &[data-header-styles="transparent"] {
    .app-header {
      border-block-end: 1px solid $default-border !important;
      .main-header-center .btn {
        color: #1b2c3f;
      }
      .main-header-container {
        .form-control {
          box-shadow: $box-shadow;
          border-color: $header-border-color !important;
          line-height: 20;
          padding: 0.3rem 1rem;
          &::placeholder {
            color: $black;
          }
        }
      }
      #mainHeaderProfile .user-name {
        color: #1b2c3f;
      }
    }
    .page-header-breadcrumb {
      color: #1b2c3f !important;
      .breadcrumb-item {
        a {
          color: $black-6 !important;
        }
        &.active {
          color: #1b2c3f !important;
        }
      }
      .breadcrumb-item + .breadcrumb-item::before {
        color: $black-6 !important;
      }
    }
    .card-background::before {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
[data-theme-mode="dark"] {
  &[data-header-styles="light"] {
    .app-header {
      border-block-end: 1px solid $default-border !important;
      .main-header-center .btn {
        color: #1b2c3f;
      }
      .main-header-container {
        .form-control {
          box-shadow: $box-shadow;
          border-color: $header-border-color !important;
          line-height: 20;
          padding: 0.3rem 1rem;
          &::placeholder {
            color: $black;
          }
        }
      }
      #mainHeaderProfile .user-name {
        color: #1b2c3f;
      }
    }
    .page-header-breadcrumb {
      color: #1b2c3f !important;
      .breadcrumb-item {
        a {
          color: rgba(0, 0, 0, 0.6) !important;
        }
        &.active {
          color: #1b2c3f !important;
        }
      }
      .breadcrumb-item + .breadcrumb-item::before {
        color: rgba(0, 0, 0, 0.6) !important;
      }
    }

    .card-background::before {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  &[data-header-styles="transparent"] {
    .card-background::before {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
.layout-setting .dark-layout {
  display: none;
}
.layout-setting .light-layout {
  display: block;
}
[data-theme-mode="dark"] {
  .layout-setting .light-layout {
    display: none;
  }
  .layout-setting .dark-layout{
    display: block;
  }
}
/* End::Header theme-mode icon style */

/* Start::Header fullscreen responsive */
@media (max-width: 767.98px) {
  .header-element.header-fullscreen {
    display: none;
  }
}

/* End::Header fullscreen responsive */
@media (max-width: 575.98px) {
  .main-header-container .header-element .header-link {
    // padding: 1rem 0.2rem;
    padding: 1rem 0;
  }
}
@media (max-width: 991px) {
  .main-header-container .header-search .header-link {
    padding: 0.85rem 0.15rem;
  }
}
