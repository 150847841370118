[data-vertical-style="doublemenu"] {
    @media (min-width: 992px) {
        .app-sidebar {
            width: 5rem;
            .main-sidebar {
                overflow: visible;
                height: 100%;
                box-shadow: none;
                margin-block-start: 0;
            }
            .main-sidebar-header {
                width: 5rem;
                position: fixed;
                .header-logo {
                    .toggle-logo {
                        display: block !important;
                    }
                    .desktop-dark,
                    .desktop-logo,
                    .toggle-dark {
                        display: none !important;
                    }
                }
            }
            .main-menu-container {
                margin-block-start: 3.75rem;
            }
            .main-menu >.slide {
                padding: 0 1.2rem;
            }
            .category-name,
            .side-menu__label,
            .side-menu__angle {
                display: none;
            }
            .side-menu__icon {
                margin-inline-end: 0;
            }
            .slide__category {
                display: none;
            }
            .simplebar-content-wrapper {
                position: initial;
            }
            .simplebar-mask {
                position: inherit;
                
            }
            .simplebar-placeholder {
                height: auto !important;
            }
        }
        .app-header {
            padding-inline-start: 17rem;
        }
        .app-content {
            margin-inline-start: 17rem;
        }
        .slide.has-sub .slide-menu {
            position: absolute !important;
            inset-inline-start: 5rem !important;
            background: $menu-bg;
            box-shadow: none;
            transition: none !important;
            height: 100% !important;
            inset-inline-end: 0;
            border-inline-end: 1px solid $default-border;
            &.child2,
            &.child3 {
                inset-inline-start: 0 !important;
                position: relative !important;
                height: auto !important;
                inset-block-start: 0 !important;
                border-inline-end: 0;
                .slide {
                    &:nth-child(2) {
                        padding-block-start: 0rem;
                    }
                }
            }
        }
        .slide-menu {
            &.child1,
            &.child2,
            &.child3 {
                .slide {
                    padding-inline-start: 0;
                    .side-menu__item {
                        text-align: start;
                        &:before {
                            inset-block-start: 0.938rem;
                            inset-inline-start: 0.75rem;
                        }
                    }
                }
                .side-menu__angle {
                    display: block;
                    inset-inline-end: 1rem;
                    inset-block-start: 0.65rem;
                }
            }
            &.child2,&.child3 {
                min-width: 10rem;
            }
            &.child1 {
                min-width: 12rem;
                .slide {
                    &:nth-child(2) {
                        padding-block-start: 0.75rem;
                    }
                }
            }
        }
        .side-menu__label1 {
            display: block !important;
            font-size: 0.938rem;
            font-weight: 500;
            color: $menu-prime-color;
            border-block-end: 1px solid $default-border;
            padding: 1.25rem 0.75rem !important;
            width: 12rem;
            // height: 3.75rem;
        }
        .slide-menu {
            display: none;

            &.double-menu-active {
                visibility: visible !important;
                display: block !important;
                height: 100% !important;
                position: absolute;
                top: 0;
                border-radius: 0 !important;
                border-block-start: 0;
                overflow: hidden auto;
            }
        }
        &[data-theme-mode="light"] {
            &[data-menu-styles="dark"],&[data-menu-styles="color"],&[data-menu-styles="gradient"] {
                .app-sidebar .main-sidebar-header {
                    .header-logo {
                        .desktop-logo,.desktop-dark,.toggle-logo {
                            display:none !important;
                        }
                        .toggle-dark {
                            display: block !important;
                        }
                    }
                }
            }
        }
        &[data-theme-mode="dark"] {
            .app-sidebar .main-sidebar-header {
                .header-logo {
                    .desktop-logo,.desktop-dark,.toggle-logo {
                        display: none !important;
                    }
                    .toggle-dark {
                        display: block !important;
                    }
                }
            }
            &[data-menu-styles="light"] {
                .app-sidebar .main-sidebar-header {
                    .header-logo {
                        .desktop-logo,.desktop-dark,.toggle-dark {
                            display: none !important;
                        }
                        .toggle-logo {
                            display: block !important;
                        }
                    }
                }   
            }
            &[data-page-style="classic"] {
                .slide.has-sub .slide-menu {
                    border-inline-end: 1px solid $default-border;
                }
            }
        }
        &[data-toggled="double-menu-close"] {
            .app-header{
                padding-inline-start: 5rem;
            }
            .app-content {
                margin-inline-start: 5rem;
            }
            .app-sidebar {
                .slide.has-sub .slide-menu {
                    display: none !important;
                }
                .main-sidebar {
                    box-shadow: $box-shadow;
                }
            }
            .page-header-breadcrumb {
                padding-block-start: 0.2rem;
                padding-inline-end: 1.45rem;
                padding-block-end: 3rem;
                padding-inline-start: 6.4rem;
            }
        }
        &[data-toggled="double-menu-open"] {
            .app-sidebar .main-sidebar {
                .slide.side-menu__label1 {
                    border-inline-end: 1px solid $default-border;
                }
            }
            .page-header-breadcrumb {
                padding-block-start: 0.2rem;
                padding-inline-end: 1.45rem;
                padding-block-end: 3rem;
                padding-inline-start: 18.4rem;
            }
        }
    }
}
[data-vertical-style="doublemenu"] {
  @media (min-width: 992px) {
    &[data-header-position="rounded"] {
      .app-header.sticky-pin {
        margin-inline-start: 16rem;
        width: calc(100% - 17rem);
        padding-inline-start: 0;
      }
      &[data-toggled="double-menu-open"] {
        .app-header.sticky-pin {
            margin-inline: 18rem;
            width: calc(100% - 19rem);
        }
      }
      &[data-toggled="double-menu-close"] {
        .app-header.sticky-pin {
            margin-inline: 6rem;
            width: calc(100% - 7rem);
        }
      }
    }
  }
}