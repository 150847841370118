.main-chart-wrapper {
  position: relative;
  overflow: hidden;
  margin: 0 -1.25rem;
  .chat-info,
  .main-chat-area {
    background-color: $custom-white;
    height: calc(100vh - 8.5rem);
    border-radius: $default-radius;
  }
  .chat-user-details {
    background-color: $custom-white;
    // height: calc(100vh - 0.5rem);
    height: calc(100vh - 3.5rem);
    border-radius: $default-radius;
  }
  .chat-users-tab,
  .chat-groups-tab,
  .chat-calls-tab {
    max-height: calc(100vh - 27rem);
  }
  .chat-content {
    max-height: calc(100vh - 16rem);
    .simplebar-content-wrapper .simplebar-content {
      margin-top: auto;
    }
    ul li {
      margin-block-end: 1rem;
      &:last-child {
        margin-block-end: 0;
      }
    }
  }
  .responsive-chat-close,button.responsive-userinfo-open {
    display: none;
  }
  .chat-info {
    position: relative;
    overflow: hidden;
    .chat-add-icon {
      position: absolute;
      inset-block-end: 0.75rem;
      inset-inline-end: 0.8rem;
      z-index: 1;
      box-shadow: 0 0 0.5rem rgba(var(--secondary-rgb), 0.5);
    }
    .nav-link {
      color: $text-muted;
      border-radius: 3px;
    }
    .tab-pane {
      padding: 0;
    }
    .chat-groups-tab {
      li {
        padding: 0.625rem 1.25rem;
      }
      .group-indivudial {
        color: $primary;
        font-weight: 600;
      }
    }
    .chat-calls-tab {
      li {
        padding: 0.625rem 1.25rem;
      }
      .incoming-call-success,
      .outgoing-call-success {
        i {
          color: $success;
          font-size: 0.875rem;
        }
      }
      .incoming-call-failed,
      .outgoing-call-failed {
        i {
          color: $danger;
          font-size: 0.875rem;
        }
      }
    }
    .chat-users-tab,
    .chat-groups-tab {
      li {
        padding: 0.625rem 1.25rem;
        .chat-msg {
          color: $text-muted;
          max-width: 11.25rem;
          display: inline-block;
        }
        .chat-msg-typing {
          .chat-msg {
            color: $success;
          }
          .chat-read-icon {
            display: none;
          }
        }
        .chat-read-icon {
          line-height: 1;
          i {
            font-size: 1rem;
            color: $success;
          }
        }
        &.chat-msg-unread {
          background-color: $default-background;
          color: $default-text-color;
          .chat-msg {
            color: $default-text-color;
          }
          .chat-read-icon {
            i {
              color: $text-muted;
            }
          }
        }
        &.chat-inactive {
          .chat-read-icon {
            display: none;
          }
        }
      }
    }
  }
  .chat-user-details {
    padding: 1.5rem;
    .avatar {
      outline: 0.25rem solid $primary-02;
    }
    .shared-files {
      li {
        margin-block-end: 1rem;
        &:last-child {
          margin-block-end: 0;
        }
      }
      .shared-file-icon i {
        // width: 1rem;
        // height: 1rem;
        line-height: 1;
        border-radius: 0.3rem;
        border: 1px solid $default-border;
        display: flex;
        align-items: center;
        // padding: 1.125rem;
        padding: 0.5rem;
        justify-content: center;
        font-size: 1.125rem;
        color: $text-muted;
      }
    }
    .chat-media {
      img {
        width: 4.5rem;
        height: 3.5rem;
        border-radius: $default-radius;
        margin-bottom: 1.05rem;
      }
    }
  }
  .main-chat-area {
    position: relative;
    .chat-content {
      background-image: url("../images/media/chat.png");
      // background-size: contain;
      background-position: center;
      background-repeat: repeat;
      background-attachment: unset;
      padding: 2.5rem;
      &::before{
        content: "";
        position: absolute;
        background-color: $primary;
        width: 100%;
        height: 100%;
        inset-block-start: 0;
        inset-inline-start: 0;
        opacity: 0.05;
      }
      .chatting-user-info {
        color: $default-text-color;
        font-weight: 600;
        font-size: 0.813rem;
        .msg-sent-time {
          color: $text-muted;
          font-size: 0.75rem;
          font-weight: 500;
          .chat-read-mark {
            i {
              color: $success;
              margin-inline-end: 0.3rem;
            }
          }
        }
      }
      .main-chat-msg div {
        margin-block-start: 0.4rem;
        padding: 0.75rem;
        width: fit-content;
        p {
          font-size: 0.813rem;
        }
        .chat-media-image {
          width: 6.25rem;
          height: 6.25rem;
          border-radius: $default-radius;
        }
      }
      .chat-item-start {
        .main-chat-msg div {
          background-color: $custom-white;
          color: $default-text-color;
          border-radius: 0 0.3rem 0.3rem 0.3rem;
        }
        .msg-sent-time {
          margin-inline-start: 0.25rem;
        }
      }
      .chat-item-end {
        justify-content: end;
        text-align: end;
        .main-chat-msg div {
          background-color: $primary;
          color: $white;
          border-radius: 0.3rem 0 0.3rem 0.3rem;
        }
        .msg-sent-time {
          margin-inline-end: 0.25rem;
        }
      }
      .chat-item-start,
      .chat-item-end {
        display: flex;
        .chat-list-inner {
          display: flex;
          align-items: top;
          max-width: 75%;
        }
      }
    }
    .chat-footer {
      width: 100%;
      box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
    }
    .chat-footer {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      height: 4.75rem;
      padding: 0 1.5rem;
      border-top: 1px solid $default-border;
      background-color: $custom-white;
      position: absolute;
      inset-inline-start: auto;
      inset-inline-end: auto;
      inset-block-end: 0;
    }
    .chat-day-label {
      text-align: center;
      color: $text-muted;
      margin-block-end: 2rem;
      opacity: 0.6;
      position: relative;
      span {
        padding: 0.188rem 0.5rem;
        font-size: 0.7rem;
        background-color: $primary-02;
        border-radius: 0.3rem;
        color: $primary;
      }
    }
  }
  @media (min-width: 992px) {
    .chat-info {
      min-width: 23.875rem;
      max-width: 23.875rem;
    }
  }
  .main-chat-area {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
  // .chat-user-details {
  //   min-width: 21.875rem;
  //   max-width: 21.875rem;
  // }
  @media (max-width: 1275.98px) and (min-width: 992px) {
    .chat-info {
      min-width: 21.875rem;
      max-width: 21.875rem;
    }
    .main-chat-area {
      width: 100%;
      max-width: 100%;
      overflow: hidden;
    }
    .chat-user-details {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .chat-info {
      width: 100%;
    }
    .main-chat-area {
      display: none;
      min-width: 100%;
      max-width: 100%;
    }
    .responsive-chat-close {
      display: block;
    }
  }
  @media (max-width:1400px) {
    .chat-user-details {
      display: none;
      // position: absolute;
      width: 100%;
      &.open {
        display: block;
        inset-inline-end: 0;
        inset-block-start: 0.5rem;
        box-shadow: $box-shadow;
        border-inline-start: 1px solid $default-border;
      }
    }
    button.responsive-userinfo-open {
      display: block;
    }
  }
}
@media (max-width:991.98px) {
  .main-chart-wrapper {
    &.responsive-chat-open {
      .chat-info {
        display: none;
      }
      .main-chat-area {
        display: block;
      }
    }
  }
}
@media (max-width: 767.98px) {
  .main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div .chat-media-image {
    width: 2.5rem;
    height: 2.5rem;
  }
}
